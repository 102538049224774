import React, {useEffect, Suspense} from 'react';
import './App.css';
import amplify from "@aws-amplify/auth";
import {Route} from "react-router";
import {BrowserRouter} from "react-router-dom";

const Page = React.lazy(() => import("./common/Page"));
const Main = React.lazy(() => import("./Main"));
const DevisCreate = React.lazy(() => import("./devis/Create"));
const DevisPage = React.lazy(() => import("./devis/DevisPage"));
const ClientsPage = React.lazy(() => import("./clients/Clients"));
const CommandePage = React.lazy(() => import("./commandes/CommandePage"));
const AuthPage = React.lazy(() => import("./auth/AuthPage"));

const App: React.FC = () => {

    const [user, setUser] = React.useState();
    const [authenticated, setAutheticated] = React.useState(false);

    useEffect(() => {
        amplify.configure({
            Auth: {
                mandatorySignIn: true,
                region: 'eu-west-1',
                userPoolId: "eu-west-1_5SgsfjAuC",
                userPoolWebClientId: "6ic4v1qsqip74haqp1sisgg2aj"
            }
        });

        amplify.currentAuthenticatedUser()
            .then( (u) => { setUser(u); setAutheticated(true); })
            .catch( () => setAutheticated(false))
    }, []);

  return (
    <div className="App">
        <BrowserRouter>
            <Suspense fallback={<div>
                <img src="/ajax-loader.gif"/><br />
                <b>Loading javascript chunk ...</b>
            </div>
            }>
                {
                    authenticated ?
                        <Page disconnectCallback={() => setAutheticated(false)}>
                            <Route exact path={"/"} render={() => (
                                <Main/>
                            )}/>
                            <Route path={'/clients'} render={() => <ClientsPage />}/>
                            <Route path={'/create/devis'} render={() => <DevisCreate/>}/>
                            <Route path={'/create/commande'} render={() => <DevisCreate isCommande/>}/>
                            <Route path={'/devis/:id'} render={(props) => <DevisPage {...props}/>}/>
                            <Route path={'/commande/:id'} render={(props) => <CommandePage {...props}/>}/>
                        </Page>
                        : <AuthPage okCallback={setAutheticated}/>
                }
            </Suspense>
        </BrowserRouter>
    </div>
  );
};

export default App;
